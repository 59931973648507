import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import List from "@material-ui/core/List";
import { ApiGet, ApiPost, ApiPut } from "../../helpers/API/ApiData";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import { Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import KycDocuments from "./KycDocuments/KycDocuments";
import CreateIcon from "@material-ui/icons/Create";
import Userlist from "./Userlist";

function Astrologer() {
  const [astrologer, setAstrologer] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loderrr, setloderrr] = useState(false);
  const [search, setSearch] = useState();
  const [viewUserInfo, setViewUserInfo] = useState(false);
  const [dataViewMore, setDataViewMore] = useState({});
  const [editAstrologer, setEditAstrologer] = useState(false);
  const [kycInformation, setKycInformation] = useState({});
  const [idForEditKyc, setIdForKyc] = useState("");
  const [errors, setErrors] = useState({});
  const [userToAstrologer, setUserToAstrologer] = useState(false);
  const [userToAstroloerData, setUserTOAstrologerData] = useState({});
  const [storeAstrologerID, setStoreAstrologerID] = useState({});
  const [getUserData, setGetUserData] = useState(false);
  const [storeData, setStoreData] = useState({});


  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAstrologerData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAstrologerData();
    }
  }, [debouncedSearchTerm]);

  // get all astrologer
  useEffect(() => {
    getAstrologerData();
  }, [page, countPerPage]);

  const getAstrologerData = async () => {
    setLoadingData(true);
    if (!search) {
      // await ApiGet(`user/admin/get-astrologer?&page=${page}&limit=${countPerPage}`)
      await ApiGet(
        `user/admin/get-astrologer?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          console.log("resresres", res);
          setLoadingData(false);
          console.log("astrologerees", res);
          setAstrologer(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    } else {
      await ApiGet(
        `user/admin/get-astrologer?search=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setLoadingData(false);
          setAstrologer(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    }
  };
  const handleOnChange = async (e) => {
    const { name, value } = e.target;
    setKycInformation({ ...kycInformation, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setKycInformation({ ...kycInformation, [name]: e.target.files[0] });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!kycInformation?.name?.trim() || kycInformation?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter Name";
    }
    if (!kycInformation?.email?.trim() || kycInformation?.email === "") {
      formvalied = false;
      errors["email"] = "*Please enter email";
    }
    if (
      !kycInformation?.description?.trim() ||
      kycInformation?.description === ""
    ) {
      formvalied = false;
      errors["description"] = "*Please enter description";
    }
    if (!kycInformation?.mobile || kycInformation?.mobile === "") {
      formvalied = false;
      errors["mobile"] = "*Please enter mobile";
    }
    if (!kycInformation?.bankName?.trim() || kycInformation?.bankName === "") {
      formvalied = false;
      errors["bankName"] = "*Please enter bankName";
    }
    if (
      !kycInformation?.bankHolderName?.trim() ||
      kycInformation?.bankHolderName === ""
    ) {
      formvalied = false;
      errors["bankHolderName"] = "*Please enter bankHolderName";
    }
    if (!kycInformation?.reAcNumber || kycInformation?.reAcNumber === "") {
      formvalied = false;
      errors["reAcNumber"] = "*Please enter account number";
    }
    if (!kycInformation?.bank_ifsc || kycInformation?.bank_ifsc === "") {
      formvalied = false;
      errors["bank_ifsc"] = "*Please enter ifsc code";
    }
    if (
      !kycInformation?.bank_branch?.trim() ||
      kycInformation?.bank_branch === ""
    ) {
      formvalied = false;
      errors["bank_branch"] = "*Please enter bank branch";
    }
    if (
      !kycInformation?.adhar_name?.trim() ||
      kycInformation?.adhar_name === ""
    ) {
      formvalied = false;
      errors["adhar_name"] = "*Please enter adhar name";
    }
    if (
      !kycInformation?.aadhar_number ||
      kycInformation?.aadhar_number === ""
    ) {
      formvalied = false;
      errors["aadhar_number"] = "*Please enter adhhar number";
    }
    if (!kycInformation?.pan_name?.trim() || kycInformation?.pan_name === "") {
      formvalied = false;
      errors["pan_name"] = "*Please enter panCard name";
    }
    if (!kycInformation?.pan_number || kycInformation?.pan_number === "") {
      formvalied = false;
      errors["pan_number"] = "*Please enter panCard number";
    }
    // if (clientData && !clientData?.file) {
    //   formvalied = false;
    //   errors["file"] = "*plese select image.";
    // }
    setErrors(errors);
    return formvalied;
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  // active-inactive astrologer
  const updatestatus = (e, row) => {
    ApiPut(
      `admin/active-deactive?id=${row?._id}&isActive=${
        row?.status === "active" ? false : true
      }`
    )
      .then((res) => {
        getAstrologerData();
        toast.success("astrologer update successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.messages);
        setloderrr(false);
      });
  };

  const editKycData = (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("name", kycInformation?.name);
      formData.append("experience", kycInformation?.experience);
      formData.append("skills", kycInformation?.skills);
      formData.append("email", kycInformation?.email);
      formData.append("description", kycInformation?.description);
      formData.append("bankDetails[name]", kycInformation?.name);
      formData.append("bankDetails[bname]", kycInformation?.bankName);
      formData.append("bankDetails[reAcNumber]", kycInformation?.reAcNumber);
      formData.append("bankDetails[ifsc]", kycInformation?.bank_ifsc);
      formData.append("bankDetails[branch]", kycInformation?.bank_branch);
      formData.append("aadharFront", kycInformation?.aadharFrontImage);
      formData.append("aadharBack", kycInformation?.aadharBackImage);
      formData.append("panFront", kycInformation?.panFrontImage);
      formData.append("panBack", kycInformation?.panBackImage);
      formData.append(
        "DocumentDetails[aadharName]",
        kycInformation?.adhar_name
      );
      formData.append(
        "DocumentDetails[aadharNumber]",
        kycInformation?.aadhar_number
      );
      formData.append("DocumentDetails[panName]", kycInformation?.pan_name);
      formData.append("DocumentDetails[panNumber]", kycInformation?.pan_number);
      formData.append("callPerMin", kycInformation?.callPerMin);
      formData.append("chatPerMin", kycInformation?.chatPerMin);

      ApiPut(`kyc/update?aid=${idForEditKyc}`, formData)
        .then((res) => {
          console.log("ddddddres", res);
          setEditAstrologer(false);
          window.location.reload()
          toast.success("astrologer update successfully");
        })
        .catch((err) => {
        
          console.log("dddddderr", err);

          toast.error("something went wrong please try again");
        });
    }
  };

  const getAllKyc = (e, data) => {
    setIdForKyc(data?._id);
    ApiGet(`kyc/find?id=${data?._id}`).then((res) => {
      console.log("Finance",res);
      // setKycInformation(res?.data?.payload?.data[res?.data?.payload?.data?.length-1]);

      const kycObject =
        res?.data?.payload?.data[res?.data?.payload?.data?.length - 1];

        const updatedData = {
          name: kycObject?.name,
          email: kycObject?.email,
          description: kycObject?.description,
          mobile: kycObject?.mobile,
          bankName: kycObject?.bankDetails?.bname,
          bankHolderName: kycObject?.bankDetails?.name,
          reAcNumber: kycObject?.bankDetails?.reAcNumber,
          bank_ifsc: kycObject?.bankDetails?.ifsc,
          bank_branch: kycObject?.bankDetails?.branch,
          adhar_name: kycObject?.DocumentDetails?.aadharName,
          aadhar_number: kycObject?.DocumentDetails?.aadharNumber,
          pan_name: kycObject?.DocumentDetails?.panName,
          pan_number: kycObject?.DocumentDetails?.panNumber,
          aadharBackImage: kycObject?.DocumentDetails?.aadharBackImage,
          aadharFrontImage: kycObject?.DocumentDetails?.aadharFrontImage,
          panBackImage: kycObject?.DocumentDetails?.panBackImage,
          panFrontImage: kycObject?.DocumentDetails?.panFrontImage,
          callPerMin: kycObject?.callPerMin,
          chatPerMin: kycObject?.chatPerMin,
          skills:kycObject?.skills,
          experience:kycObject?.experience,
        }
        console.log('updatedData',updatedData);
      setKycInformation(updatedData);
    });
  };

  console.log('KycInformation',kycInformation);
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const handleShowInformation = (e, data) => {
    setDataViewMore(data);
    setViewUserInfo(true);
  };

  const handleViewUserInfoClose = () => {
    setViewUserInfo(false);
    setDataViewMore({});
  };
  const editAstrlogerClose = () => {
    setEditAstrologer(false);
    // setDataViewMore({});
  };
  const showUserToAstrologer = () => {
    setUserToAstrologer(false);
    // setDataViewMore({});
  };
  const handleOnChnageAddAstroImg = (e ,row) => {
    const { name } = e.target;
    setStoreData({ ...storeData, [name]: e.target.files[0] });
    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    ApiPut(`user/admin/update-user?id=${row?._id}`, formData)
        .then((res) => {
            toast.success("Image upload successfully");
            getAstrologerData();
        })
        .catch((err) => { })


};


  const columns = [
    {
      name: "sNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Astrologer Name",
      selector: (row) => row?.userName,
      sortable: true,
    },
    {
      name: "Phone number",
      selector: (row) => row?.phoneNo,
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "status",
      selector: (row) => (row?.status === "active" ? "active" : "inactive"),
      sortable: true,
    },
    {
      name: "Users",

      cell: (row) => {
        return (
          <>
            <div>
              {
                row.usertoastrorequested === 'requested' ? (
                  <small className="badge badge-primary">Requested for <br/> Astrologer.</small>
                ) : (
                  <i
                  className="fa fa-users text-dark"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setStoreAstrologerID(row?._id);
                    setGetUserData(true);
                  }}
                ></i>
                )
              }
             
            </div>
          </>
        );
      },
      width: "10%",
      sortable: true,
    },
    {
      name: "Profile image",
      cell: (row) => {
        return (
          <>
          {row?.profileImage ? 
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.profileImage} width={100} height={50} />
            </div>
            :<p style={{color:"red"}}>No profile</p> }
          </>
        );
      },
      sortable: true,
      width: "12%",
    },
    {
      name: "status",

      cell: (row) => {
        return (
          <>
            <button
              className={
                row?.status === "active"
                  ? " btn btn-active-color"
                  : "btn btn-danger"
              }
              onClick={(e) => {
                updatestatus(e, row);
              }}
            >
              <span style={{ whiteSpace: "nowrap" }}>
                {" "}
                {row?.status === "active" ? "active" : "inactive"}
              </span>
            </button>
          </>
        );
      },
      // width: "10%",
      sortable: true,
    },
    {
      name: "KYC Document",
      cell: (row) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleShowInformation(e, row);
              }}
            >
              <Tooltip title="Show More" arrow>
                {/* <InfoOutlinedIcon /> */}
                <button className="btn btn-warning" type="button">Approve/Reject</button>
              </Tooltip>
            </div>
            <div
              className="cursor-pointer pl-2"
              onClick={(e) => {
                setEditAstrologer(true);
                getAllKyc(e, row);
              }}
            >
              <Tooltip title="Edit KYC" arrow>
                {/* <CreateIcon /> */}
                <button className="btn btn-warning" type="button">Edit KYC</button>
              </Tooltip>
            </div>
          </>
        );
      },
      sortable: true,
      width: "20%",
    },
    {
      name: "User to Astrologer",
      cell: (row) => {
        return (
          <>
            {row?.userToAstro === null ? (
              ""
            ) : (
              <button
                style={{ marginLeft: "4%" }}
                className="btn btn-warning"
                onClick={() => {
                  setUserToAstrologer(true);
                  setUserTOAstrologerData(row);
                }}
              >
                View Request
              </button>
            )}
          </>
        );
      },
      sortable: true,
      width: "13%",
    },
    {
      name: "Add Profile image",
      cell: (row) => {
        return (
          <>
            <div>

<Tooltip title="Add profile image" arrow>
    <div className="add-alignment">

        <i className="fa-solid fa-plus plus-icon"></i>
        <input type="file" name="file"
            id="file"
            onChange={(e) => handleOnChnageAddAstroImg(e, row)} />
    </div>

</Tooltip>

</div>
          </>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Astrologer</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Suche"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height"></div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={astrologer}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          )}
        </div>
      </div>

      {viewUserInfo ? (
        <Dialog
          fullScreen
          open={viewUserInfo}
          onClose={handleViewUserInfoClose}
        >
          <div className="cus-modal-close" onClick={handleViewUserInfoClose}>
            <CloseIcon className="close-icon" />
          </div>
          <List>
            <div>
              {viewUserInfo === true ? (
                <>
                  <KycDocuments
                    dataViewMore={dataViewMore}
                    handleAllInfoClose={handleViewUserInfoClose}
                    viewUserInfo={viewUserInfo}
                    setViewUserInfo={setViewUserInfo}
                  />
                </>
              ) : null}
            </div>
          </List>
        </Dialog>
      ) : null}

      <>
        {editAstrologer ? (
          <Dialog
            fullScreen
            open={editAstrologer}
            onClose={editAstrlogerClose}
            // TransitionComponent={Transition}
          >
            <div className="cus-modal-close" onClick={editAstrlogerClose}>
              <CloseIcon className="close-icon" />
            </div>
            <List>
              <div>
                {editAstrologer === true ? (
                  <>
                    {kycInformation?.name === undefined ? (
                      <h2>no kyc data</h2>
                    ) : (
                      <div className="all-info-white-banner">
                    
                        <div className="page-title-alignment">
                          {/* PERSONAL INFORMATION */}
                          <h3>Personal Information:</h3>
                          <div className="first-box-design">
                            <div className="first-box-input-grid">
                              <div className="input">
                                <label>Full Name</label>
                                <input
                                  type="text"
                                  name="name"
                                  value={kycInformation?.name}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["name"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  value={kycInformation?.email}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["email"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Description</label>
                                <input
                                  type="text"
                                  name="description"
                                  value={kycInformation?.description}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["description"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Mobile</label>
                                <input
                                  type="text"
                                  name="mobile"
                                  value={kycInformation?.mobile}
                                  onChange={(e) => handleOnChange(e)}
                                  onKeyPress={bindInput}
                                  disabled={true}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["mobile"]}
                                </span>
                              </div>

                              <div className="input">
                                    <label>Skill</label>
                                      {console.log('kycInformation?.skills',kycInformation?.skills)}
                                    <select className="form-control py-1"  name="skills"  onChange={(e) => handleOnChange(e)}>
                                        <option selected={kycInformation?.skills === 'Nadi'}>Nadi</option>
                                        <option selected={kycInformation?.skills === 'Vedic'}>Vedic</option>
                                        <option selected={kycInformation?.skills === 'Numerology'}>Numerology</option>
                                        <option selected={kycInformation?.skills === 'Vastu'}>Vastu</option>
                                        <option selected={kycInformation?.skills === 'Prashana'}>Prashana</option>
                                    </select>
                                    <span
                                        style={{
                                            color: "red",
                                            top: "5px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {errors["skills"]}
                                    </span>
                              </div>


                                <div className="input">
                                <label>Experience</label>
                                <input
                                  type="text"
                                  name="experience"
                                  value={kycInformation?.experience}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["experience"]}
                                </span>
                              </div>

                            </div>
                          </div>

                          {/* BANK DETALS */}

                          <h3 className="mt-10">Bank Information:</h3>
                          <div className="first-box-design">
                            <div className="first-box-input-grid">
                              <div className="input">
                                <label>Bank Name</label>
                                <input
                                  type="text"
                                  name="bankName"
                                  value={kycInformation?.bankName}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["bankName"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Bank Holder Name</label>
                                <input
                                  type="text"
                                  name="bankHolderName"
                                  value={kycInformation?.bankHolderName}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["bankHolderName"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Bank Account Number</label>
                                <input
                                  type="text"
                                  name="reAcNumber"
                                  value={kycInformation?.reAcNumber}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["reAcNumber"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  name="bank_ifsc"
                                  value={kycInformation?.bank_ifsc}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["bank_ifsc"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Branch</label>
                                <input
                                  type="text"
                                  name="bank_branch"
                                  value={kycInformation?.bank_branch}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["bank_branch"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* DOCUMENT DETAILS */}

                          <h3 className="mt-10">Document Details:</h3>
                          <div className="first-box-design">
                            <div className="first-box-input-grid">
                              <div className="input">
                                <label>Adhar Name</label>
                                <input
                                  type="text"
                                  name="adhar_name"
                                  value={kycInformation?.adhar_name}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["adhar_name"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Adhar Number</label>
                                <input
                                  // disabled
                                  type="text"
                                  name="aadhar_number"
                                  value={kycInformation?.aadhar_number}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["aadhar_number"]}
                                </span>
                              </div>

                              {/* aadharBackImage */}
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  file
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div>
                                    <div className="labelMainDiv">
                                      <label
                                        htmlFor="imageUpload"
                                        className="lableForImage"
                                      >
                                        select file
                                      </label>
                                      {kycInformation?.aadharBackImage ? (
                                        ""
                                      ) : (
                                        <label className="labelselectfile">
                                          no file selected
                                        </label>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="aadharBackImage"
                                      id="imageUpload"
                                      hidden
                                      onChange={(e) => handleOnChnageAddImg(e)}
                                    />
                                  </div>
                                  <div>
                                    {kycInformation?.aadharBackImage && (
                                      <img
                                        style={{
                                          height: "150px",
                                          width: "270px",
                                          margin: "15px 0",
                                        }}
                                        src={
                                          kycInformation?.aadharBackImage
                                            ?.toString()
                                            ?.includes(".com") === true
                                            ? kycInformation?.aadharBackImage
                                            : URL.createObjectURL(
                                                kycInformation?.aadharBackImage
                                              )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  file
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div>
                                    <div className="labelMainDiv">
                                      <label
                                        htmlFor="aadharFrontImage"
                                        className="lableForImage"
                                      >
                                        select file
                                      </label>
                                      {kycInformation?.aadharFrontImage ? (
                                        ""
                                      ) : (
                                        <label className="labelselectfile">
                                          no file selected
                                        </label>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="aadharFrontImage"
                                      id="aadharFrontImage"
                                      hidden
                                      onChange={(e) => handleOnChnageAddImg(e)}
                                    />
                                  </div>
                                  <div>
                                    {/* {console.log("helooweq", URL?.createObjectURL(kycInformation?.aadharFrontImage
                                      ))} */}

                                    {kycInformation?.aadharFrontImage?.slice(
                                      -4
                                    ) === ".pdf" ? (
                                      <>
                                        <div className="mt-3">
                                          <a
                                            href={
                                              kycInformation?.aadharFrontImage
                                            }
                                            target="_"
                                          >
                                            Ausgewähltes Dokument
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      kycInformation?.aadharFrontImage && (
                                        <img
                                          style={{
                                            height: "150px",
                                            width: "270px",
                                            margin: "15px 0",
                                          }}
                                          src={
                                            kycInformation?.aadharFrontImage
                                              ?.toString()
                                              ?.includes(".com") === true
                                              ? kycInformation?.aadharFrontImage
                                              : URL.createObjectURL(
                                                  kycInformation?.aadharFrontImage
                                                )
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="input">
                                <label>Pan Name</label>
                                <input
                                  type="text"
                                  name="pan_name"
                                  value={kycInformation?.pan_name}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["pan_name"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Pan Number</label>
                                <input
                                  type="text"
                                  name="pan_number"
                                  value={kycInformation?.pan_number}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["pan_number"]}
                                </span>
                              </div>

                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  file
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div>
                                    <div className="labelMainDiv">
                                      <label
                                        htmlFor="panBackImage"
                                        className="lableForImage"
                                      >
                                        select file
                                      </label>
                                      {kycInformation?.panBackImage ? (
                                        ""
                                      ) : (
                                        <label className="labelselectfile">
                                          no file selected
                                        </label>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="panBackImage"
                                      id="panBackImage"
                                      hidden
                                      onChange={(e) => handleOnChnageAddImg(e)}
                                    />
                                  </div>
                                  <div>
                                    {/* {console.log("helooweq", URL?.createObjectURL(kycInformation?.panBackImage
                                      ))} */}

                                    {kycInformation?.panBackImage?.slice(-4) ===
                                    ".pdf" ? (
                                      <>
                                        <div className="mt-3">
                                          <a
                                            href={kycInformation?.panBackImage}
                                            target="_"
                                          >
                                            Ausgewähltes Dokument
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      kycInformation?.panBackImage && (
                                        <img
                                          style={{
                                            height: "150px",
                                            width: "270px",
                                            margin: "15px 0",
                                          }}
                                          src={
                                            kycInformation?.panBackImage
                                              ?.toString()
                                              ?.includes(".com") === true
                                              ? kycInformation?.panBackImage
                                              : URL.createObjectURL(
                                                  kycInformation?.panBackImage
                                                )
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* <div className="input">
                    <label>Pan Front Image</label>
                    <div>
                      {panFront === true ? (
                        <iframe
                          src={
                            KycInformation[0]?.DocumentDetails?.panFrontImage
                          }
                          height="250px"
                          width="300px"
                        />
                      ) : (
                        <img
                          src={
                            KycInformation[0]?.DocumentDetails?.panFrontImage
                          }
                          alt=""
                          height="250px"
                          width="300px"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>  */}

                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label">
                                  file
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                  <div>
                                    <div className="labelMainDiv">
                                      <label
                                        htmlFor="panFrontImage"
                                        className="lableForImage"
                                      >
                                        select file
                                      </label>
                                      {kycInformation?.panFrontImage ? (
                                        ""
                                      ) : (
                                        <label className="labelselectfile">
                                          no file selected
                                        </label>
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      className={`form-control form-control-lg form-control-solid `}
                                      name="panFrontImage"
                                      id="panFrontImage"
                                      hidden
                                      onChange={(e) => handleOnChnageAddImg(e)}
                                    />
                                  </div>
                                  <div>
                                    {/* {console.log("helooweq", URL?.createObjectURL(kycInformation?.panFrontImage
                                      ))} */}

                                    {kycInformation?.panFrontImage?.slice(
                                      -4
                                    ) === ".pdf" ? (
                                      <>
                                        <div className="mt-3">
                                          <a
                                            href={kycInformation?.panFrontImage}
                                            target="_"
                                          >
                                            Ausgewähltes Dokument
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      kycInformation?.panFrontImage && (
                                        <img
                                          style={{
                                            height: "150px",
                                            width: "270px",
                                            margin: "15px 0",
                                          }}
                                          src={
                                            kycInformation?.panFrontImage
                                              ?.toString()
                                              ?.includes(".com") === true
                                              ? kycInformation?.panFrontImage
                                              : URL.createObjectURL(
                                                  kycInformation?.panFrontImage
                                                )
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                          <h3>Change Rate:</h3>
                          <div className="first-box-design">
                            <div className="first-box-input-grid">
                              <div className="input">
                                <label>Call per m minute</label>
                                <input
                                  type="number"
                                  name="callPerMin"
                                  value={kycInformation?.callPerMin}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors["callPerMin"]}
                                </span>
                              </div>

                              <div className="input">
                                <label>Chat per minute</label>
                                <input
                                  type="number"
                                  name="chatPerMin"
                                  value={kycInformation?.chatPerMin}
                                  onChange={(e) => handleOnChange(e)}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    top: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {
                                    console.log('kycInformation',kycInformation)
                                  }
                                  {errors["chatPerMin"]}
                                </span>
                              </div>
                            </div>
                          </div>
                        {viewUserInfo === false ? (
                          <div className="d-flex justify-content-center my-3">
                            <button
                              onClick={(e) => editKycData(e)}
                              className="edit-button"
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </List>
          </Dialog>
        ) : null}

        {userToAstrologer === true ? (
          <Dialog
            fullScreen
            open={userToAstrologer}
            onClose={() => showUserToAstrologer()}
          >
            <div
              className="cus-modal-close"
              onClick={() => showUserToAstrologer()}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon className="close-icon" />
            </div>
            <List>
              <div className="container-md-new">
              
                <div className="user-information-box-content-alignment">
                  <h2>User to Astrologer Details:</h2>
                  {/* <div className="all-text-grid-alignment">
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>User name:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userName
                            ? userToAstroloerData?.userName
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>gender:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.gender
                            ? userToAstroloerData?.userToAstro?.gender
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>Qualification:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.Qualification
                            ? userToAstroloerData?.userToAstro?.Qualification
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>qualities Of Astrologer:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro
                            ?.qualitiesOfAstrologer
                            ? userToAstroloerData?.userToAstro
                                ?.qualitiesOfAstrologer
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>language:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.language
                            ? userToAstroloerData?.userToAstro?.language
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>learn Astrology:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.learnAstrology
                            ? userToAstroloerData?.userToAstro?.learnAstrology
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>on Board:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.onBoard
                            ? userToAstroloerData?.userToAstro?.onBoard
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>primary Skill:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.primarySkill
                            ? userToAstroloerData?.userToAstro?.primarySkill
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>about Astrovarta:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.aboutAstrovarta
                            ? userToAstroloerData?.userToAstro?.aboutAstrovarta
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>collage:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.collage
                            ? userToAstroloerData?.userToAstro?.collage
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>hourly Contribute:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.hourlyContribute
                            ? userToAstroloerData?.userToAstro?.hourlyContribute
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>currant Job Status:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.currantJobStatus
                            ? userToAstroloerData?.userToAstro?.currantJobStatus
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>currant Live:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.currantLive
                            ? userToAstroloerData?.userToAstro?.currantLive
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>experiance:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.experiance
                            ? userToAstroloerData?.userToAstro?.experiance
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>expectation:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.expectation
                            ? userToAstroloerData?.userToAstro?.expectation
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>dob:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.dob
                            ? userToAstroloerData?.userToAstro?.dob
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>Facebook link:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.facebook
                            ? userToAstroloerData?.userToAstro?.facebook
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>Instagram link:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.instagram
                            ? userToAstroloerData?.userToAstro?.instagram
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>Linkdin link:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.linkedIn
                            ? userToAstroloerData?.userToAstro?.linkedIn
                            : "--"}
                        </span>
                      </div>
                    </div>
                    <div className="text-grid">
                      <div className="text-grid-items">
                        <p>currant Job Status:-</p>
                      </div>
                      <div className="text-grid-items">
                        <span>
                          {userToAstroloerData?.userToAstro?.currantJobStatus
                            ? userToAstroloerData?.userToAstro?.currantJobStatus
                            : "--"}
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="card p-3">

                 
                    <div class="row">
                      <div class="col-lg-6">
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <td><b>User Name</b></td>
                              <td> {userToAstroloerData?.userName
                            ? userToAstroloerData?.userName
                            : "--"}</td>
                            </tr>
                        
                            <tr>
                              <td><b>Contact Number</b></td>
                              <td> {userToAstroloerData?.phoneNo
                            ? userToAstroloerData?.phoneNo
                            : "--"}</td>
                            </tr>

                            <tr>
                              <td><b>Gender</b></td>
                              <td> {userToAstroloerData?.userToAstro?.gender
                            ? userToAstroloerData?.userToAstro?.gender
                            : "--"}</td>
                            </tr>

                            <tr>
                              <td><b>Dob</b></td>
                              <td> {userToAstroloerData?.userToAstro?.dob
                            ? userToAstroloerData?.userToAstro?.dob
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Language</b></td>
                            
                              <td> {
                                userToAstroloerData?.userToAstro?.language
                                  ? userToAstroloerData?.userToAstro?.language
                                  : "--"
                              }</td>
                            </tr>
                            <tr>
                              <td><b>Qualification</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.Qualification
                            ? userToAstroloerData?.userToAstro?.Qualification
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Degree/Diploma</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.degree
                            ? userToAstroloerData?.userToAstro?.degree
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>College/School/University</b></td>
                              <td> {userToAstroloerData?.userToAstro?.collage
                            ? userToAstroloerData?.userToAstro?.collage
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Currently live in city?</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.currantLive
                            ? userToAstroloerData?.userToAstro?.currantLive
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Primary Skills</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.primarySkill
                            ? userToAstroloerData?.userToAstro?.primarySkill
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Other Skills</b></td>
                              <td> {
                                userToAstroloerData?.userToAstro?.allSkill
                                  ? userToAstroloerData?.userToAstro?.allSkill : "--"
                              }</td>
                            </tr>
                            {
                              console.log('detail fof',userToAstroloerData)
                            }
                            <tr>
                              <td><b>Learn Astrology from?</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.learnAstrology
                            ? userToAstroloerData?.userToAstro?.learnAstrology
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Experience</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.experiance
                            ? userToAstroloerData?.userToAstro?.experiance
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>How many hours contribut?</b></td>
                              <td> {userToAstroloerData?.userToAstro?.hourlyContribute
                            ? userToAstroloerData?.userToAstro?.hourlyContribute
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Why we onboard ?</b></td>
                              <td> {userToAstroloerData?.userToAstro?.onBoard
                            ? userToAstroloerData?.userToAstro?.onBoard
                            : "--"}</td>
                            </tr>

                         
                        
                           
                          </tbody>
                        </table>
                      </div>
                      <div class="col-lg-6">
                        <table class="table table-striped">
                        
                          <tbody>
                            <tr>
                              <td><b>Suitable time for interview?</b></td>
                              <td> {userToAstroloerData?.userToAstro?.interview
                            ? userToAstroloerData?.userToAstro?.interview
                            : "--"}</td>
                            </tr>
                        
                            <tr>
                              <td><b>Expectation from Astrovarta?</b></td>
                              <td> {userToAstroloerData?.userToAstro?.expectation
                            ? userToAstroloerData?.userToAstro?.expectation
                            : "--"}</td>
                            </tr>

                            <tr>
                              <td><b>Currently working</b></td>
                              <td> {userToAstroloerData?.userToAstro?.currantJobStatus
                            ? userToAstroloerData?.userToAstro?.currantJobStatus
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Good qualities of Astrologer?</b></td>
                              <td>  {userToAstroloerData?.userToAstro?.qualitiesOfAstrologer
                            ? userToAstroloerData?.userToAstro?.qualitiesOfAstrologer
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Bio</b></td>
                              <td> {userToAstroloerData?.userToAstro?.longBio
                            ? userToAstroloerData?.userToAstro?.longBio
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Biggest Challenge Faced</b></td>
                              <td> {userToAstroloerData?.userToAstro?.challanges
                            ? userToAstroloerData?.userToAstro?.challanges
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Customer asking same question</b></td>
                              <td> {userToAstroloerData?.userToAstro?.sameQuestion
                            ? userToAstroloerData?.userToAstro?.sameQuestion
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>No of Foreign countries live/travelled?</b></td>
                              <td> {userToAstroloerData?.userToAstro?.foreignTravel
                            ? userToAstroloerData?.userToAstro?.foreignTravel
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Where did hear about Astrovarta?</b></td>
                              <td>{userToAstroloerData?.userToAstro?.aboutAstrovarta
                            ? userToAstroloerData?.userToAstro?.aboutAstrovarta
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Working on other platform?</b></td>
                              <td>{userToAstroloerData?.userToAstro?.otherOnlineWork
                            ? "Yes"
                            : "No"}</td>
                            </tr>

                            <tr>
                              <td><b>Facebook link</b></td>
                              <td> {userToAstroloerData?.userToAstro?.facebook
                            ? userToAstroloerData?.userToAstro?.facebook
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Instagram link</b></td>
                              <td> {userToAstroloerData?.userToAstro?.instagram
                            ? userToAstroloerData?.userToAstro?.instagram
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Linkdin link</b></td>
                              <td> {userToAstroloerData?.userToAstro?.linkedIn
                            ? userToAstroloerData?.userToAstro?.linkedIn
                            : "--"}</td>
                            </tr>
                            <tr>
                              <td><b>Youtube link</b></td>
                              <td> {userToAstroloerData?.userToAstro?.youtube
                            ? userToAstroloerData?.userToAstro?.youtube
                            : "--"}</td>
                            </tr>

                            <tr>
                              <td><b>Website link</b></td>
                              <td> {userToAstroloerData?.userToAstro?.website
                            ? userToAstroloerData?.userToAstro?.website
                            : "--"}</td>
                            </tr>
                        
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
              <div></div>
            </List>
          </Dialog>
        ) : null}
      </>
      {getUserData === true && (
        <>
          <Dialog
            fullScreen
            open={getUserData}
            onClose={() => setGetUserData(false)}
          >
            <div
              className="cus-modal-close"
              onClick={() => setGetUserData(false)}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon className="close-icon" />
            </div>
            <List>
              <Userlist storeAstrologerID={storeAstrologerID} />
            </List>
          </Dialog>
        </>
      )}
    </>
  );
}
export default Astrologer;
