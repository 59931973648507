import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";
import { Modal, Button, Form } from "react-bootstrap";


function SupportUser() {
  const [contactUs, setContactUs] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [status, setStatus] = useState("");

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getUserData();
  }, [page, countPerPage]);

  const getUserData = async () => {
    setLoadingData(true);
    try {
      const endpoint = search
        ? `user-contact-us/get-support=${search}&page=${page}&limit=${countPerPage}`
        : `user-contact-us/get-support?page=${page}&limit=${countPerPage}`;
      const res = await ApiGet(endpoint);
      setLoadingData(false);
      setContactUs(res?.data?.payload?.data);
      setCount(res?.data?.payload?.count);
    } catch (err) {
      setLoadingData(false);
    }
  };

  const handleActionClick = (row) => {
    setSelectedRow(row);
    setStatus(row?.status || "Open");
    setShowStatusModal(true);
  };

  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setShowDetailModal(true);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedRow || !status) {
      toast.error("Please select a row and a status before submitting!");
      return;
    }

    try {
      await ApiPut(`user-contact-us/update-status`, {
        id: selectedRow._id,
        status: status,
      });

      toast.success("Status updated successfully!");
      setShowStatusModal(false);
      getUserData();
    } catch (err) {
      toast.error(err.message || "Failed to update status!");
    }
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Ticket No.",
      cell: (row) => <p className="pt-2">{row?._id}</p>,
    },
    {
      name: "Name",
      selector: (row) => row?.fname,
      sortable: true,
    },
    {
      name: "E mail",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row?.message,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <p
        className={`badge btn-sm ${
          row?.status === "Open"
            ? "badge-danger"
            : row?.status === "Closed"
            ? "closeStatusColor"
            : "badge-success"
        }`}
      >
        {row?.status}
      </p>
      ),
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm mr-2"
            onClick={() => handleActionClick(row)}
          >
            Update Status
          </button>
          <button
            className="btn btn-info btn-sm"
            onClick={() => handleViewDetails(row)}
          >
            View
          </button>
        </>
      ),
      width: "200px", 
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">User Customer Support</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid`}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={contactUs}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(rowPerPage) => setCountPerPage(rowPerPage)}
            />
          )}
        </div>
      </div>

      <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={handleStatusChange}
            >
              <option value="Open">Open</option>
              <option value="In Progress">In Progress</option>
              <option value="Closed">Closed</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <div>
              <p>
                <strong>Ticket No:</strong> {selectedRow?._id}
              </p>
              <p>
                <strong>Name:</strong> {selectedRow?.fname}
              </p>
              <p>
                <strong>Email:</strong> {selectedRow?.email}
              </p>
              <p>
                <strong>Phone:</strong> {selectedRow?.phone}
              </p>
             
              <p>
                <strong>Status:</strong> {selectedRow?.status}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {moment(selectedRow?.createdAt).format("Do MMM YYYY")}
              </p>
              <p>
                <strong>Message:</strong> {selectedRow?.message}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SupportUser;
