import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";

function Chat(props) {
  const { storeRoomId } = props;
  console.log("******", storeRoomId);
  const [getchat, setGetchat] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [getRooms, setGetRooms] = useState([]);

  // const[idforData,setidforData]
  console.log("getRooms", getRooms?.[0]?.senderId?.role);
  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllChats();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllChats();
    }
  }, [debouncedSearchTerm]);

  // get all getchat
  useEffect(() => {
    getAllChats();
  }, [page, countPerPage]);
  useEffect(() => {
    handleGetRoomAccordingChat();
  }, []);

  const handleGetRoomAccordingChat = async () => {
    setLoadingData(true);
    await ApiGet(`admin/chat-history?roomId=${storeRoomId?.roomId}`)
      .then((res) => {
        setLoadingData(false);
        console.log("@@@@@", res);
        console.log("contactus", res?.data?.payload?.chatHistory);
        setGetRooms(res?.data?.payload?.chatHistory);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  const getAllChats = async () => {
    setLoadingData(true);
    let url = search ? `&letter=${search}` : "";
    await ApiGet(`admin/chat-history?roomId=634841a45d68bfe97b3beb7a`)
      .then((res) => {
        setLoadingData(false);
        console.log("#####", res);
        //   setGetchat(res?.data?.payload?.data);
        setCount(res?.data?.payload?.count);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "E mail",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row?.message,
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="mb-4 pr-3 w-1000px m-auto chat-box-alignment">
            {/* <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Chat</h2>
            </div> */}
           
            {getRooms?.map((item, index) => {
              return (
                <>
                  <div>
                    {item?.senderId?._id !== storeRoomId?.userId?._id ? (
                      <>
                        <div className="d-flex align-items-center mb-2">
                          <div className="profileBox">
                            {item?.senderId?.role[0]?.toUpperCase()}
                          </div>
                          <div>
                          <div className="child-text-style">
                              <span>{item?.senderId?.userName}</span>
                          </div>
                          <div className="chat-box-design mt-2">
                            
                            <div className="d-flex justify-content-start">
                              {console.log(
                                "sfdsdfsdfsd",
                                item?.data?.slice(0, 5)
                              )}

                              {item?.data?.slice(0, 5) === "https" ||
                              item?.data?.slice(0, 4) === "http" ? (
                                <>
                                  <img
                                    src={item?.data}
                                    alt="chatImage"
                                    height={100}
                                    width={100}
                                  />
                                </>
                              ) : (
                                <span>{item?.data}</span>
                              )}
                            </div>
                          </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-end mb-2">
                          <div>
                          <div className="child-text-style-new">
                              <span>{item?.senderId?.userName}</span>
                          </div>
                          <div className="d-flex justify-content-end sender-message-alignment mt-2">
                            {item?.data?.slice(0, 5) === "https" ||
                            item?.data?.slice(0, 4) === "http" ? (
                              <>
                                <img
                                  src={item?.data}
                                  alt="chatImage"
                                  height={100}
                                  width={100}
                                />
                              </>
                            ) : (
                              <span>{item?.data}</span>
                            )}
                          </div>
                          </div>
                          <div className="profileBox senderCHat">{item?.senderId?.role[0]?.toUpperCase()}</div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}

            {/* </> */}
            {/* )
            })} */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Chat;
