import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";

export default function Banner() {
  const [couponData, setCouponData] = useState({});
  const [addCoupan, setAddCoupon] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCoupon, setGetAllCoupon] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  //for pagation
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [couponIdForDelete, setCouponIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);
  const [clientData, setClientData] = useState({});
  const [offerContainer, setOfferContainer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState(null);

  const [fieldsName, setFieldsName] = useState({
    banner: "Banner Name",
    offerName: "Offer Name",
    miniamotoApply: "Minimum Amount To Apply Offer"
  });
  useEffect(() => {
    getAllCouponData();
  }, [page, countPerPage]);

  //For close category model
  const addCategoryModelClose = (e) => {
    setAddCoupon(false);
    setIsEditApi(false);
    setErrors(false);
    setCouponData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    if (name === "category" && value === "cashback") {
      setOfferContainer(true);
      setFieldsName({
        banner: "Offer Name",
        offerName: "Offer Code",
        miniamotoApply: "Offer Amount"
      });
    } else if (value === "chat" || value === "call") {
      setOfferContainer(false);
      setFieldsName({
        banner: "Banner Name",
        offerName: "Offer Name",
        miniamotoApply: "Minimum Amount To Apply Offer"
      });
    }

    setCouponData({ ...couponData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllCouponData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`banner/get?page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setGetAllCoupon(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `banner/get?letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllCoupon(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);

          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addCouponData = async (e) => {
    if (couponData?.name == undefined || couponData?.category == undefined) {
      return toast.error("All fields are required.");
    }
    setIsEditApi(false);
    setLoader(true);
    let formData = new FormData();
    formData.append("name", couponData?.name);
    formData.append("category", couponData?.category);
    formData.append("offerName", couponData?.offerName);
    formData.append("minimumAmmount", couponData?.minimumAmmount);
    formData.append("cashback", couponData?.cashback);
    formData.append("file", clientData?.file);
    if (editMode) {
      formData.append("couponId", selectedCouponId);
      await ApiPost(`banner/update`, formData)
        .then((res) => {
          res?.data?.success == false
            ? toast.error(res?.data?.messages)
            : toast.success(res?.data?.messages);
          setAddCoupon(false);
          setClientData({});
          getAllCouponData();
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    } else {
      await ApiPost(`banner/create`, formData)
        .then((res) => {
          res?.data?.success == false
            ? toast.error(res?.data?.messages)
            : toast.success(res?.data?.messages);
          setAddCoupon(false);
          setClientData({});
          getAllCouponData();
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  // For Delete category Api
  const deleteCouponData = async () => {
    await ApiPut(`banner/delete?id=${couponIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Banner delete successfully");
          setShow(false);
          getAllCouponData();
        } else {
          toast.error("Something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong please try again");
      });
  };

  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCouponData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCouponData();
    }
  }, [debouncedSearchTerm]);

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setClientData({ ...clientData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const openEditModal = (coupon) => {
    if (coupon?.category == "cashback") {
      setOfferContainer(true);
      setFieldsName({
        banner: "Offer Name",
        offerName: "Offer Code",
        miniamotoApply: "Offer Amount"
      });
    } else {
      setFieldsName({
        banner: "Banner Name",
        offerName: "Offer Name",
        miniamotoApply: "Minimum Amount To Apply Offer"
      });
      setOfferContainer(false);
    }

    setCouponData(coupon);
    setSelectedCouponId(coupon._id);
    setAddCoupon(true);
    setEditMode(true);
  };

  // -------------------------DATA TABLE--------------------

  const customStyles = {
    header: {
      style: {
        minHeight: "56px"
      }
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default
      }
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default
        }
      }
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default
        }
      }
    }
  };

  const columns = [
    {
      name: "SNr",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%"
    },
    {
      name: "Banner Name",
      selector: (row) => row?.name,
      sortable: true,
      width: "15%"
    },
    {
      name: "Category",
      selector: (row) => (row?.category ? row?.category : "-"),
      sortable: true,
      width: "15%"
    },
    {
      name: "offer Name",
      selector: (row) => (row?.offerName ? row?.offerName : "-"),
      sortable: true,
      width: "15%"
    },

    {
      name: "Min. Amt.",
      selector: (row) => (row?.minimumAmmount ? row?.minimumAmmount : "-"),
      sortable: true,
      width: "15%"
    },
    {
      name: "Cashback %",
      selector: (row) => (row?.cashback ? row?.cashback : "-"),
      sortable: true,
      width: "15%"
    },
    {
      name: "Banner",
      cell: (row) => {
        return (
          <>
            <div className="mx-auto my-3">
              {row?.file && row?.file != "undefined" ? (
                <img src={row?.file} srcSet="" width={260} height={80} />
              ) : (
                "-"
              )}
            </div>
          </>
        );
      },
      width: "30%",
      sortable: true
    },

    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%"
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            {/* edit category */}
            <div className="d-flex justify-content-between mr-3">
              <Tooltip title="Edit coupon" arrow>
                <EditIcon
                  className="cursor-pointer"
                  onClick={() => openEditModal(row)}
                />
              </Tooltip>
              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCouponIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete coupan" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%"
    }
  ];

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Banner</h2>
            </div>

            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddCoupon(true);
                }}
              >
                Add Banner
              </button>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllCoupon}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alarm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Banner?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteCouponData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Open add photographer model */}
      {addCoupan === true ? (
        <Dialog fullScreen open={addCoupan}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCategoryModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {fieldsName?.banner}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="name"
                      required={true}
                      value={couponData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px"
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Category
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      className={`form-control form-control-lg form-control-solid `}
                      id="category"
                      name="category"
                      value={couponData?.category}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      required={true}
                    >
                      <option value="">Select Category</option>
                      <option value="chat">Chat</option>
                      <option value="call">Call</option>
                      <option value="cashback">Cashback</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px"
                    }}
                  >
                    {errors["category"]}
                  </span>
                </div>
              </div>

              {offerContainer && (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      {fieldsName?.offerName}
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="text"
                          className={`form-control form-control-lg form-control-solid `}
                          id="offerName"
                          name="offerName"
                          value={couponData?.offerName}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px"
                        }}
                      >
                        {errors["offerName"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      {fieldsName?.miniamotoApply}
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="number"
                          className={`form-control form-control-lg form-control-solid `}
                          id="minimumAmmount"
                          name="minimumAmmount"
                          value={couponData?.minimumAmmount}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px"
                        }}
                      >
                        {errors["minimumAmmount"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Cashback %
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="number"
                          className={`form-control form-control-lg form-control-solid `}
                          id="cashback"
                          name="cashback"
                          value={couponData?.cashback}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px"
                        }}
                      >
                        {errors["cashback"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Wallet Offer
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          id="isWalletOffer"
                          name="isWalletOffer"
                          value={couponData?.isWalletOffer}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          required={true}
                        >
                          <option value="False">False</option>
                          <option value="True">True</option>
                        </select>
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px"
                        }}
                      >
                        {errors["isWalletOffer"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Popular Offer
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          id="isPopularOffer"
                          name="isPopularOffer"
                          value={couponData?.isPopularOffer}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          required={true}
                        >
                          <option value="False">False</option>
                          <option value="True">True</option>
                        </select>
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px"
                        }}
                      >
                        {errors["isPopularOffer"]}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {!offerContainer && (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    file
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <div className="labelMainDiv">
                        <label htmlFor="imageUpload" className="lableForImage">
                          select file
                        </label>
                        {clientData?.companyLogo ? (
                          ""
                        ) : (
                          <label className="labelselectfile">
                            no file selected
                          </label>
                        )}
                      </div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="file"
                        id="imageUpload"
                        hidden
                        required={true}
                        onChange={(e) => handleOnChnageAddImg(e)}
                      />
                    </div>
                    <div>
                      {clientData?.file && (
                        <img
                          style={{
                            height: "150px",
                            width: "270px",
                            margin: "15px 0"
                          }}
                          src={
                            clientData?.file?.toString()?.includes(".com") ===
                            true
                              ? clientData?.file
                              : URL.createObjectURL(clientData?.file)
                          }
                        />
                      )}
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px"
                      }}
                    >
                      {errors["file"]}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                      Add Coupan
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    addCouponData(e);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
