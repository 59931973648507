import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../_metronic/_assets/sass/layout/_basic.scss";
import axios from "axios";
import { set } from "lodash";

export default function Login() {
  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const regexemailId =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!loginData.emailId && !loginData.password) {
      setErrors({
        emailId: "Email Id is required*",
        password: "Password is required*",
      });
    } else if (loginData.emailId === "" && loginData.password === "") {
      setErrors({ ...errors, emailId: "Email Id is required*" });
    } else if (!loginData.emailId || loginData.emailId === "") {
      setErrors({ ...errors, emailId: "Email Id is required*" });
    } else if (
      !loginData.emailId ||
      regexemailId.test(loginData.emailId) === false
    ) {
      setErrors({ ...errors, emailId: "Email Id is not valid*" });
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Password is required*" });
    } else {
      loginData.emailId = loginData.emailId.toLowerCase();
      await ApiPost("admin/login", loginData)
        .then((res) => {
          if (res.data.error === "emailId Does Not Exist") {
            setErrors({ user: "User does not exist !!" });
          } else if (res.data.error === "Password Incorrect") {
            setErrors({
              user: "Login credentials are incorrect !!",
            });
          } else {
            console.log("loginres", res.data);
            toast.success(res?.data?.message);
            authUtil.setToken(res.data.payload.token);
            userUtil.setUserInfo(res.data.payload.userDetails);
            // history.push("/");
            window.location.reload();
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    setLoader(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // history.push("/dashboard");
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Admin Dashboard</h3>
        <p className="text-muted font-weight-bold">
          Enter your login credentials.
        </p>

        <span className="text-danger h6">{errors.user}</span>
      </div>
      {/* 
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      /> */}
      <form onSubmit={(e) => handleKeyPress(e)}>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email Id"
            type="emailId"
            className={`form-control form-control-solid h-auto py-5 px-6  `}
            name="emailId"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <span className="text-danger">{errors.emailId}</span>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            name="password"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <span className="text-danger">{errors.password}</span>
        </div>
        <div>
          {/* <a href="/auth/forgot-password" style={{marginLeft:"280px"}}>
        Forgot password?
        </a> */}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`align-items-center d-flex btn btn-color font-weight-bold px-9 py-4 my-3`}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <span className="pr-2">Log In</span>
            {loader && (
              <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </form>

      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Read our <Link>Onboarding Policy</Link> here.
          </span>
        </div> */}
      {/* </form> */}

      {/*end::Form*/}
    </div>
  );
}
