/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* dashboard */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <i
                className="fa-solid fa-display menu-text"
                style={{ fontSize: "13px", color: "#383839" }}
              ></i>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
          
        {/* astrologer */}
        <li
          className={`menu-item ${getMenuItemActive("/astrologer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/astrologer">
            <span className="svg-icon menu-icon">
               <i
              className="fa-sharp fa-solid fa-globe menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Astrologer</span>
          </NavLink>
        </li>
        {/* user */}
        <li
          className={`menu-item ${getMenuItemActive("/total-userlist", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/total-userlist">
            <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            <span className="menu-text">User</span>
          </NavLink>
        </li>

        {/* blog */}
        <li
          className={`menu-item ${getMenuItemActive("/blog", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/blog">
          <i
              className="fa-solid fa-newspaper menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            <span className="menu-text">Blog</span>
          </NavLink>
        </li>
    
    {/* coupon */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/Coupen", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Coupen">
            <span className="svg-icon menu-icon">
            <i
              className="fa-solid fa-ticket menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Coupon</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/Banner", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Banner">
            <span className="svg-icon menu-icon">
            <i
              className="fa-solid fa-ticket menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Banner / Cashback</span>
          </NavLink>
        </li>


        {/* client */}
        <li
          className={`menu-item ${getMenuItemActive("/client", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Client</span>
          </NavLink>
        </li>
        
         {/* contact us */}
         <li
          className={`menu-item ${getMenuItemActive("/contact-us", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/contact-us">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Astrologer Contact-Us</span>
          </NavLink>
        </li>


        <li
          className={`menu-item ${getMenuItemActive("/feedback", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/feedback">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Feedback</span>
          </NavLink>
        </li>


        <li
          className={`menu-item ${getMenuItemActive("/support-user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/support-user">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">User Customer Support</span>
          </NavLink>
        </li>

        {/* Raise ticket */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/user-chat", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-chat">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa-user menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Chat</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/raise-ticket", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/raise-ticket">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa fa-ticket menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Astrologer Raise Ticket</span>
          </NavLink>
        </li>


        {/* <li
          className={`menu-item ${getMenuItemActive("/astrologer-transaction", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/astrologer-transaction">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa fa-credit-card menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Astrologer Transaction</span>
          </NavLink>
        </li> */}


        
        <li
          className={`menu-item ${getMenuItemActive("/astro-transaction-detail", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/astro-transaction-detail">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa fa-credit-card menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Astrologer Transaction Logs</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/user-paymentlogs", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-paymentlogs">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa fa-credit-card menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">User Payment Logs</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/notification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification">
            <span className="svg-icon menu-icon">
               <i
              className="fa-solid fa fa-bell menu-text"
              style={{ flex: " 0 0 35px" }}
            ></i>
            </span>
            <span className="menu-text">Notification</span>
          </NavLink>
        </li>
        
      </ul>
     
    </>
  );
}
