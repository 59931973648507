import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";
import DatePicker from "react-datepicker";

function Userpaymentlogs() {
  const [userwallet, setUserwallet] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalAmt, setTotalAmt] = useState(0);
  const [cashbackAmt, setCashbackAmt] = useState(0);



  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date?.getFullYear(), date?.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  // const[idforData,setidforData]

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);

  // get all userwallet
  useEffect(() => {
    getUserData();
  }, [page, countPerPage, startDate, endDate]);

  const getUserData = async () => {
    setLoadingData(true);
    if (!search) {
      await ApiGet(
        `payment/get-payment-transaction?letter=${search}&page=${page}&limit=${countPerPage}&startDate=${moment(
          startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`
      )
        .then((res) => {
          setLoadingData(false);
          setTotalAmt(res?.data?.payload?.result[0]?.totalAmount)
          setCashbackAmt(res?.data?.payload?.result[0]?.totalCashback)
          console.log('res?.data?.payload?.findOrder',res?.data?.payload?.findOrder);
          setUserwallet(res?.data?.payload?.findOrder);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    } else {
      await ApiGet(
        `payment/get-payment-transaction?letter=${search}&page=${page}&limit=${countPerPage}&startDate=${moment(
          startDate
        ).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`
      )
        .then((res) => {
          setLoadingData(false);
          setUserwallet(res?.data?.payload?.findOrder);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    }
  };

  console.log("userwallet", userwallet);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "User Name",
      selector: (row) => row?.userId?.userName,
      sortable: true,
    },
    {
      name: "Recharged Amount",
      selector: (row) => "₹ " + parseInt(row?.amount) / 100,
      sortable: true,
    },
    {
      name: "Cashback Amount",
      cell: (row) => {
        return (
          <>
            <div className="mx-auto my-3 text-success">
              {!!row?.cashbackAmt ? <b>₹ {row?.cashbackAmt}</b> : "-"}
            </div>
          </>
        );
      },
      width: "15%",
      sortable: true,
    },
    {
      name: "Offer Name",
      selector: (row) => (row?.offerName ? row?.offerName : "-"),
      sortable: true,
    },
    // {
    //   name: "Offer Name",
    //   cell: (row) => {
    //     return (
    //       <>
    //         {row?.aid?.profileImage ? (
    //           <div className="mt-4 mb-4 p-1 border border-dark rounded">
    //             <img src={row?.aid?.profileImage} width={100} height={50} />
    //           </div>
    //         ) : (
    //           "-"
    //         )}
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },

    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            <div className="my-3 text-success">
              {row?.status == "paid" ? (
                <b className="badge badge-success">
                  {" "}
                  {row?.status.charAt(0).toUpperCase() + row?.status.slice(1)}
                </b>
              ) : (
                <b className="badge badge-danger">
                  {" "}
                  {row?.status.charAt(0).toUpperCase() + row?.status.slice(1)}
                </b>
              )}
            </div>
          </>
        );
      },
      width: "15%",
      sortable: true,
    },

    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "15%",
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">User Payment Logs</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="search"
                  className={`form-control form-control-lg form-control-solid `}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height"> </div>
          </div>

          <div class="row my-4">
            <div className="col-lg-3">
                      <div className="card p-4">
                          <h5>Successful Payments</h5>
                            <h4 className="text-success py-3">{parseInt(totalAmt)/100}</h4>
                      </div>          
            </div>

                  <div className="col-lg-3">
                      <div className="card p-4">
                            <h5>Cashback Amount</h5>
                            <h4 className="text-success py-3">{cashbackAmt}</h4>
                      </div>  
                   </div>


                   <div className="col-lg-6">
                      <div className="card p-4">
                      <div className="new-margin-bottom-alignment d-flex gap-3 mb-5 mt-3 ml-5">
                <>
                <div className="row">
                  <div className="col-lg-6">
                    <label
                      style={{
                        margin: "0",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgb(0, 42, 90)",
                        width: "100%",
                      }}
                    >
                      Transaction From :
                    </label>
                   
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label
                      style={{
                        margin: "0",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "rgb(0, 42, 90)",
                        width: "100%",
                      }}
                    >
                      Transaction To :
                    </label>
                    <br/>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </div>
                </div>
                </>
              </div>
                      </div>  
                   </div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={userwallet}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default Userpaymentlogs;
