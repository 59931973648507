import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {ApiGet,ApiPost,ApiPut,ApiDelete,} from "../../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";


export default function Client() {
  const [clientData, setClientData] = useState({});
  const [addClient, setAddClient] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllClient, setGetAllClient] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  //for pagation
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState();
  const [clientIdForDelete, setClientIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);

  
  useEffect(() => {
    getAllClientData();
    
  }, [page, countPerPage]);

  //For close category model
  const addClientModelClose = (e) => {
    setAddClient(false);
    setIsEditApi(false);
    setErrors(false);
    setClientData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setClientData({ ...clientData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  //Form validation
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!clientData?.name?.trim() || clientData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter Name.";
    }
    if (clientData && !clientData?.file) {
      formvalied = false;
      errors["file"] = "*plese select image.";
    }
    if (!clientData?.description?.trim() || clientData?.description === "") {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }
    if (!clientData?.city?.trim() || clientData?.city === "") {
      formvalied = false;
      errors["city"] = "*Please enter city";
    }
    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  //Api for get all companydata
  const getAllClientData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`client/get?page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setGetAllClient(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(`client/get?letter=${search}&page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setGetAllClient(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  //Api For add category
  const addClientData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", clientData?.name);
      formData.append("file", clientData?.file);
      formData.append("city", clientData?.city);
      formData.append("description", clientData?.description);
      
      await ApiPost(`client/create`, formData)
      .then((res) => {  
          toast.success("Blog add successfully");
          setAddClient(false);
          setIsEditApi(false);
          getAllClientData();
          setClientData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  // Api for edit category Data
  const editClientData = async (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("name", clientData?.name);
      formData.append("file", clientData?.file);
      formData.append("description", clientData?.description);
      formData.append("role", "628c56134ca60c2cf0d70a24");
      formData.append("city", clientData?.city);
      

      setLoader(true);

      await ApiPut(`client/update?id=${categoryIdForEdit}`, formData)
        .then((res) => {
          toast.success("Blog update successfully");
          getAllClientData();
          setAddClient(false);
          setIsEditApi(false);
          setClientData([]);
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  // For Delete category Api
  const deleteClientData = async () => {
    await ApiPut(`client/delete?id=${clientIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete blog successfully");
          setShow(false);
          getAllClientData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };

  //For search and pegination

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllClientData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllClientData();
    }
  }, [debouncedSearchTerm]);

  // -------------------------DATA TABLE--------------------
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            {ReactHtmlParser(
             row?.description?.length > 200
                ?row?.description?.substring(0, 200) + "..."
                :row?.description
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city,
      sortable: true,
    },

    {
      name: "File",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.profileImage} width={100} height={50} />
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            {/* edit category */}
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddClient(true);
                  setCategoryIdForEdit(row?._id);
                  setClientData({
                    name: row?.name,
                    file: row?.profileImage,
                    description: row?.description,
                    city:row?.city,

                  });
                }}
              >
                <Tooltip title="edit blog" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setClientIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete blog" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Client</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddClient(true);
                }}
              >
                Add Client
              </button>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllClient}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alarm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Client?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteClientData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Open add photographer model */}
      {addClient === true ? (
        <Dialog fullScreen open={addClient}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addClientModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="name"
                      value={clientData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  file
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="imageUpload" className="lableForImage">
                        select file
                      </label>
                      {clientData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="file"
                      id="imageUpload"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {clientData?.file && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          clientData?.file
                            ?.toString()
                            ?.includes(".com") === true
                            ? clientData?.file
                            : URL.createObjectURL(clientData?.file)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["file"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      className={`form-control form-control-lg form-control-solid `}
                      id="description"
                      name="description"
                      value={clientData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  City
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="city"
                      name="city"
                      value={clientData?.city}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["city"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                      {isEditApi ? "Edit  Client" : "Add Client"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    isEditApi ? editClientData(e) : addClientData(e);
                  }}
                >
                  {isEditApi ? "Edit  Client" : "Add Client"}
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
