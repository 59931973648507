import React, { useEffect, useState } from "react";
import { getUserInfo } from "../../../../utils/user.util";
import { NavLink } from "react-router-dom";
// import Shop from "../../../../_metronic/components/Shop/Shop";
// import Manufacture from "../../../../_metronic/components/Manufacture/Manufacture";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";

export function MixedWidget1({ className }) {
  let userInfo = getUserInfo();
  const [adminData, setAdminData] = useState();
  const [selectedTable, setSelectedTable] = useState("Order");
  const [totalCount, setTotalCount] = useState();
  const [title, setTitle] = useState();
  const [totalBlog, setTotalBlog] = useState();
  const [totalCoupan, setTotalCoupan] = useState();
  const [totalClient, setTotalClient] = useState();
  const [totalUser, setTotaluser] = useState();
  const [totalAstologer, setTotalAstologer] = useState();

  const history = useHistory();

  useEffect(() => {
    document.title = "Astrovarta | Dashboard";
    setTitle("Astrovarta | Dashboard");
  }, []);

  useEffect(() => {
    getAllBlog();
    getAllCoupan();
    getAllClient();
    getAllUser();
    getAllAstologer();
  }, []);

  // useEffect(() => {
  //   console.log("user", userInfo);
  //   if (userInfo?.role === "superadmin") {
  //     getcount();
  //   }
  // }, []);

  const getAllBlog = async () => {
    await ApiGet(`blog/find`).then((res) => {
      setTotalBlog(res?.data?.payload?.count);
    });
  };

  const getAllCoupan = async () => {
    await ApiGet(`coupan/get`).then((res) => {
      setTotalCoupan(res?.data?.payload?.count);
    });
  };

  const getAllClient = async () => {
    await ApiGet(`client/get`).then((res) => {
      setTotalClient(res?.data?.payload?.count);
    });
  };

  const getAllUser = async () => {
    await ApiGet(`user/get-user?user=true`).then((res) => {
      setTotaluser(res?.data?.payload?.count);
    });
  };

  const getAllAstologer = async () => {
    await ApiGet(`user/get-user?astrologer=true`).then((res) => {
      setTotalAstologer(res?.data?.payload?.count);
    });
  };

  const jumpOnBlog = (e) => {
    history.push("/blog");
  };

  const jumpOnCoupan = (e) => {
    history.push("/Coupen");
  };
  const jumpOnClient = (e) => {
    history.push("/client");
  };
  const jumpOnUser = (e) => {
    history.push("/userlist");
  };
  const jumpOnAstrologer = (e) => {
    history.push("/astrologer");
  };
  // const getcount = async () => {
  //   await ApiGet(`admin/count`)
  //     .then((res) => {
  //       console.log("res741852", res?.data?.payload);
  //       if (res?.status == 200) {
  //         setTotalCount(res?.data?.payload);
  //       } else {
  //         toast.error(res?.data?.message);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message);
  //     });
  // };

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* <ToastContainer /> */}
      {/* Header */}
      <>
        <div
          className={`card card-custom`}
          style={{ backgroundColor: "#3F4255" }}
        >
          <div className="card-header border-0  py-1 px-1 m-5">
            <div className="card-body p-0 position-relative overflow-hidden">
              <div className="card-rounded-bottom" style={{ height: "25px" }}>
                <h4 className="font-weight-bolder text-white pt-2 pl-6">
                  Dashboard
                </h4>
              </div>

              <>
                <div className="card-spacer">
                  <div
                    className="carder-box"
                    style={{
                      display: "grid",
                      gap: "12px",
                      gridTemplateColumns: "repeat(4,1fr)",
                      padding: "20px",
                    }}
                  ></div>
                </div>
              </>

              <>
                <div className="card-spacer">
                  <div
                    className="carder-box"
                    style={{
                      display: "grid",
                      gap: "12px",
                      gridTemplateColumns: "repeat(5,2fr)",
                      padding: "20px",
                    }}
                  >

                    
                    {/* astrologer */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalAstologer && (
                                  <CountUp
                                    end={totalAstologer}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <i
                                className="fa-sharp fa-solid fa-globe"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Astrologer
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnAstrologer(e)}
                      >
                        <span style={{cursor:"pointer"}}>
                          {" "}
                          show more{" "}
                          <span className="fa-solid fa-arrow-right-long ml-2" style={{cursor:"pointer"}}></span>
                        </span>
                      </div>
                    </div>
                    {/* user */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalUser && (
                                  <CountUp
                                    end={totalUser}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <i
                                className="fa-solid fa-user"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          User
                        </span>
                      </div>
                      <div className="showMore" onClick={(e) => jumpOnUser(e)}>
                        <span style={{cursor:"pointer"}}>
                          {" "}
                          show more{" "}
                          <span className="fa-solid fa-arrow-right-long ml-2" style={{cursor:"pointer"}}></span>
                        </span>
                      </div>
                    </div>

                    {/* blog */}
                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalBlog && (
                                  <CountUp
                                    end={totalBlog}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <i
                                className="fa-solid fa-newspaper"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Blog
                        </span>
                      </div>
                      <div className="showMore" onClick={(e) => jumpOnBlog(e)}>
                        <span style={{cursor:"pointer"}}>
                          {" "}
                          show more{" "}
                          <span className="fa-solid fa-arrow-right-long ml-2" style={{cursor:"pointer"}}></span>
                        </span>
                      </div>
                    </div>

                    {/* coupan */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalCoupan && (
                                  <CountUp
                                    end={totalCoupan}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <i
                                className="fa-solid fa-ticket"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Coupon
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnCoupan(e)}
                      >
                        <span style={{cursor:"pointer"}}>
                          {" "}
                          show more{" "}
                          <span className="fa-solid fa-arrow-right-long ml-2" style={{cursor:"pointer"}}></span>
                        </span>
                      </div>
                    </div>

                    {/* client */}

                    <div className="bg-light-warning p-6 rounded">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h1
                                className="text-warning font-weight-bold"
                                style={{ fontSize: "40px" }}
                              >
                                {totalClient && (
                                  <CountUp
                                    end={totalClient}
                                    start={0}
                                    delay={1}
                                  />
                                )}
                              </h1>
                            </div>
                            <div style={{ display: "flex", marginTop: "6px" }}>
                              <i
                                className="fa-solid fa-user"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        <span
                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                          style={{ cursor: "pointer" }}
                        >
                          Client
                        </span>
                      </div>
                      <div
                        className="showMore"
                        onClick={(e) => jumpOnClient(e)}
                      >
                        <span style={{cursor:"pointer"}}>
                          {" "}
                          show more{" "}
                          <span className="fa-solid fa-arrow-right-long ml-2" style={{cursor:"pointer"}}></span>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
